import { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { IUseSessionErrors, ResetState, UseContextSSO } from './interfaces'
import { logoutThunk, loginSSO } from '../../modules/login/sessionSlice'
import LocalStorageManager from '../../helpers/localStorage'

export const useSession: IUseSessionErrors = (loadingLogin, sessionErrors) => {
  const dispatch = useDispatch()
  const [incorrectPassword, setIncorrectPassword] = useState<boolean>(false)
  const [blockedAccess, setBlockedAccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const resetState = (state: ResetState[]): void => {
    if (state?.includes('blockedAccess')) setBlockedAccess(false)
    if (state?.includes('incorrectPassword')) setIncorrectPassword(false)
    if (state?.includes('isError')) setIsError(false)
    if (state?.includes('isLoading')) setIsLoading(false)
    if (state?.includes('isLoading')) setIsLoading(false)
    if (state?.includes('all')) dispatch(logoutThunk())
  }

  useEffect(() => {
    if (loadingLogin === 'pending') {
      setIsLoading(true)
    }

    if (
      loadingLogin === 'failing' &&
      sessionErrors?.error === 'incorrectPassword' &&
      sessionErrors.info?.missing_attempts === 1
    ) {
      setIncorrectPassword(true)
    }

    if (loadingLogin === 'failing' && sessionErrors?.error === 'userIsBlocked') {
      setBlockedAccess(true)
    }

    if (loadingLogin === 'success' || loadingLogin === 'failing') {
      setIsLoading(false)
    }

    if (
      sessionErrors &&
      loadingLogin === 'failing' &&
      sessionErrors.error === 'incorrectPassword' &&
      sessionErrors.info?.missing_attempts
    ) {
      setIsError(true)
    } else if (sessionErrors && loadingLogin === 'failing') {
      setIsError(true)
    }
  }, [loadingLogin, sessionErrors])

  return {
    incorrectPassword,
    blockedAccess,
    isLoading,
    isError,
    resetState
  }
}

export const useContextSSO: UseContextSSO = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { search } = useLocation()

  const queryParams = useMemo(() => new URLSearchParams(search), [search])
  const patientEpisodeNumber = queryParams.get('person')
  const target = queryParams.get('target')

  useEffect(() => {
    const signatureParams = queryParams.get('signature')
    const refreshSignatureParams = queryParams.get('refreshSignature')
    const externalUsernameParams = queryParams.get('user')
    if (signatureParams && externalUsernameParams && refreshSignatureParams) {
      const signature = signatureParams.split(' ').join('+')
      const refreshSignature = refreshSignatureParams.split(' ').join('+')
      const externalUsername = externalUsernameParams

      dispatch(loginSSO(signature, externalUsername, refreshSignature))
      LocalStorageManager.WriteEncryptedData<boolean>('is_context_sso-encrypted', true)
      LocalStorageManager.WriteEncryptedData<string | null>('episode_number-encrypted', patientEpisodeNumber)

      setLoading(true)
    }
  }, [])

  return [loading, { patientEpisodeNumber, target }]
}
